require( 'owl.carousel' );

import home from './modules/home';

jQuery( document ).ready(function($) {

  // init home
  home();

});
