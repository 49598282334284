
export default function home() {
  
  $('.page-home .hero').owlCarousel({
    items: 1,
    autoplay: false,
    loop: true,
    smartSpeed: 2000,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut'
  });

  $('#nav-features').click(function(e){
    e.preventDefault();

    $('.page-home .hero').trigger('next.owl.carousel');
  });

  $('a.from-the-editor').hover(function(){
    $('.page-home .hero').trigger('to.owl.carousel', [$(this).data('slide-no')]);
    $("body").get(0).style.setProperty("--main-color", $(this).data('color'));
  });

  $('.features li a').hover(function(){
    $('.page-home .hero').trigger('to.owl.carousel', [$(this).data('slide-no')]);
    $("body").get(0).style.setProperty("--main-color", $(this).data('color'));
  });

  $('.landing video').click(function(e){
    e.preventDefault();
    $('.landing').fadeOut('slow');
  });

  setTimeout(function(){
    $('.landing').fadeOut('slow');
  }, 10000);

}